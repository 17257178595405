import { createSlice } from "@reduxjs/toolkit";
// import { getSatuan } from "./satuan";
import axios from "axios";
import qs from 'qs';
import { authToken } from "./user";

export const getPendanaan = () => async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}master/get_pendanaan`);
        return dispatch(get_pendanaan(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const getPendanaanId = (id_pendanaan)=>async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}master/getPendanaanId/${id_pendanaan}`);
        return dispatch(get_pendanaan_id(res.data));
    } catch (error) {
      console.log(error);  
    }
}

export const revisiPendanaan = (data) =>async(dispatch,getState)=>{
    try {
        data.pagu = data.pagu.replace(/[^0-9]/g,'');
        await axios.post(`${process.env.REACT_APP_URL}master/update_pendanaan`,qs.stringify(data));
        return dispatch(getParent(localStorage.getItem('tahun')));
    } catch (error) {
        console.log(error);
    }
}

export const deletePendanaanInduk = (id_pendanaan) => async(dispatch,getState) => {
    try {
        await axios.post(`${process.env.REACT_APP_URL}master/delete_pendanaanInduk`,qs.stringify({id_pendanaan:id_pendanaan}))
        return dispatch(getParent(localStorage.getItem('tahun')));
    } catch (error) {
        console.log(error);
    }
}

export const createPendanaan = (data) => async(dispatch,getState)=>{
    try {
        data.pagu = data.pagu.replace(/[^0-9]/g,'');
        await axios.post(`${process.env.REACT_APP_URL}master/create_pendanaan`,qs.stringify(data));
        return dispatch(getParent(localStorage.getItem('tahun')));
    } catch (error) {
        console.log(error);
    }
}

export const getParent = (tahun) => async(dispatch,getState)=>{
    try {
        const res = tahun !== 0 && await axios.get(`${process.env.REACT_APP_URL}master/get_parent/${tahun}`,{
            headers:{
                Authorization:'Bearer '+localStorage.getItem('token')
            }
        });

        // ==============proses authentikasi token ===================
        const authError = authToken(res);
        if (authError) {
            return authError;
        }
        // =======================================================

        return dispatch(get_pendanaan(res.data));
        
    } catch (error) {
        console.log(error);
    }
}

export const getChild = (data) => async(dispatch)=>{
    try {
        const res = await axios.post(`${process.env.REACT_APP_URL}master/get_child`,qs.stringify(data));
        return dispatch(get_child(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const getIndikatorParent = (data) => async(dispatch)=>{
    try {
        const res = await axios.post(`${process.env.REACT_APP_URL}master/get_indikator_parent`,qs.stringify(data));
        return dispatch(set_indikator(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const createIndikator = (data) => async(dispatch)=>{
    try {
        data.perencanaan = data.perencanaan.replace(/[^0-9]/g,'');
        await axios.post(`${process.env.REACT_APP_URL}master/create_indikator`,qs.stringify(data));
        return dispatch(getIndikatorParent({id_pendanaan:data.id_pendanaan}));
    } catch (error) {
        console.log(error);
    }
}

export const getIndikatorId = (id_indikator)=>async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}master/get_indikatorId/${id_indikator}`);
        return dispatch(set_indikator_id(res.data));
    } catch (error) {
      console.log(error);  
    }
}

export const setSubmitRevisi = (data) =>async(dispatch)=>{
    try {
        await axios.post(`${process.env.REACT_APP_URL}master/set_submit_revisi`,qs.stringify(data));
    } catch (error) {
        console.log(error);
    }
}


export const submitPembatalanIndikator = (data) => async(dispatch) =>{
    try {
       const res = await axios.post(`${process.env.REACT_APP_URL}master/submit_pembatalan_indikator`,qs.stringify(data));
        return dispatch(set_alert(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const updateIndikatorId = (updateIndikatorId)=>async(dispatch)=>{
    try {
        //console.log(updateIndikatorId);
        dispatch(update_indikator_id(updateIndikatorId));
    } catch (error) {
        console.log(error);
    }
}

export const getCapaian = (data) =>async(dispatch,getState)=>{
    try {
        const { tahun } = getState().User; 
        const datanew = {...data,tahun}
        const res = await axios.post(`${process.env.REACT_APP_URL}master/get_capaian_kinerja`,qs.stringify(datanew));
        return dispatch(set_capaian(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const createCapaianKinerja = (data) =>async(dispatch) => {
    try {
        //console.log(data);
        await axios.post(`${process.env.REACT_APP_URL}master/create_capaian_kinerja`,qs.stringify(data.capaian));
        await dispatch(getCapaian({id_pendanaan:data.id_pendanaan}));
    } catch (error) {
        console.log(error);
    }
}

export const getSelectPendanaan = (tahun) => {
    return async (dispatch) => {
        //console.log(tahun !== 0);
        try {
            const res = tahun !== 0 && await axios.get(`${process.env.REACT_APP_URL}master/get_select_pendanaan/${tahun}`);
           await dispatch(set_select_pendanaan(res?.data));
        } catch (error) {
            
        }
    };
  };
  


export const createSelectPendanaan = (data) => async(dispatch) =>{
    try {
        await axios.post(`${process.env.REACT_APP_URL}master/create_select_pendanaan`, qs.stringify(data));
        const tahun = localStorage.getItem('tahun'); // Mendapatkan nilai tahun dari localStorage
        await dispatch(getSelectPendanaan(tahun)); // Dispatch aksi dengan nilai tahun
      } catch (error) {
        console.log(error);
      }
}

export const getBidang = ()=> async(dispatch) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}master/get_bidang`,{
            headers:{
                Authorization:'Bearer '+localStorage.getItem('token')
            }
        });

        // ==============proses authentikasi token ===================
        const authError = authToken(res);
        if (authError) {
            return authError;
        }
        // =======================================================

        return await dispatch(set_bidang(res.data));

    } catch (error) {
        console.log(error);
    }
}

export const createBidang = (data)=>async(dispatch)=>{
    try {
        await axios.post(`${process.env.REACT_APP_URL}master/create_bidang`,qs.stringify(data));
    } catch (error) {
        console.log(error);
    }
}

export const deleteBidang = (data)=>async(dispatch)=>{
    try {
        //console.log(data);
        await axios.post(`${process.env.REACT_APP_URL}master/delete_bidang`,qs.stringify(data),{
            headers:{
                Authorization:'Bearer '+localStorage.getItem('token')
            }
        });
        await dispatch(getBidang());
    } catch (error) {
        console.log(error);
    }
}


export const getUser = () =>async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}master/get_user`,{
            headers:{
                Authorization:'Bearer '+localStorage.getItem('token')
            }
        });
        await dispatch(set_user(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const createUser = (data) => async(dispatch)=>{
    try {
        await axios.post(`${process.env.REACT_APP_URL}master/create_user`,qs.stringify(data));
        await dispatch(getUser());
    } catch (error) {
        console.log(error);
    }
}

const Master = createSlice({
  name: "master",
  initialState: {
    pendanaan: [],
    pendanaanId:[],
    child: [],
    indikator: [],
    indikatorId:[],
    capaian: [],
    selectPendanaan: [],
    bidang:[],
    user:[],
    alert:null
  },
  reducers: {
    get_pendanaan(state, action) {
      state.pendanaan = action.payload;
    },
    get_pendanaan_id(state, action) {
      state.pendanaanId = action.payload;
    },
    get_child(state, action) {
      state.child.push(action.payload);
    },
    set_bidang(state, action) {
        state.bidang = action.payload;
    },
    set_indikator(state, action) {
      state.indikator = action.payload;
    },
    set_capaian(state, action) {
      state.capaian = action.payload;
    },
    set_select_pendanaan(state, action) {
      state.selectPendanaan = action.payload;
    },
    set_user(state,action){
        state.user = action.payload;
    },
    set_indikator_id(state,action){
        state.indikatorId = action.payload;
    },
    update_indikator_id(state,action){
        state.indikatorId = action.payload;
    },
    set_alert(state,action){
        console.log(action.payload.msg);
        state.alert = action.payload.msg;
    }
  },
});

const {get_pendanaan,get_pendanaan_id,get_child,set_indikator,set_capaian,set_select_pendanaan,set_bidang,set_user,set_indikator_id,update_indikator_id,set_alert} = Master.actions;

export default Master;
