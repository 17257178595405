import { DeleteOutline, DeleteOutlineOutlined, LibraryAdd, ModeEditOutline, MoreVert } from "@mui/icons-material";
import { IconButton, ListItemIcon, Menu, MenuItem, MenuList } from "@mui/material";
import React from "react";

const ButtonPendanaan = ({status,id_pendanaan,anchorEl,handleClick,handleClose2,idPendanaan,addPagu,revisiPagu,deletePagu,statusBtn}) => {
  return (
    <>
      <IconButton
        id={`menu-button${id_pendanaan}`}
        data-id_pendanaan={id_pendanaan}
        data-status = {status}
        aria-controls={
          Boolean(anchorEl) ? `menu-button${id_pendanaan}` : undefined
        }
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id={`menu-drop${id_pendanaan}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose2}
        MenuListProps={{ "aria-labelledby": `menu-button${id_pendanaan}` }}
      >
        <MenuList sx={{ width: 150, maxWidth: "100%" }}>
        {statusBtn === "parent" && (
            <MenuItem onClick={(e) => addPagu(idPendanaan)} disableRipple>
              <ListItemIcon>
                  <LibraryAdd />
                </ListItemIcon>
                  Add Pagu
             </MenuItem>
           )}
            <MenuItem onClick={(e) => revisiPagu(idPendanaan)} disableRipple>
              <ListItemIcon>
                <ModeEditOutline />
              </ListItemIcon>
              Revisi
            </MenuItem>
            <MenuItem onClick={(e) => deletePagu(idPendanaan)} disableRipple>
              <ListItemIcon>
                <DeleteOutlineOutlined />
              </ListItemIcon>
                Delete
            </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default ButtonPendanaan;
