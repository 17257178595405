import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getParent,
  createPendanaan,
  getPendanaanId,
  revisiPendanaan,
  deletePendanaanInduk,
} from "../../store/master";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { LibraryAdd, ModeEditOutline, MoreVert } from "@mui/icons-material";
import { formatRupiah, rupiah } from "../../function/FormatRupiah";
import { RowTop, TableTop } from "../../assets/styled";
import { logoutUser } from "../../store/user";
import { useNavigate } from "react-router-dom";
import DialogPendanaanAdd from "../../component/DialogPendanaanAdd";
import DialogPendanaanRevisi from "../../component/DialogPendanaanRevisi";
import ButtonPendanaan from "../../component/ButtonPendanaan";

const StyledButton = styled(Button)({
  backgroundColor: "#4875EE",
  color: "#ffffff",
  margin: "10px",
});

const TableCells = styled(TableCell)({
  border: "1px solid black",
});

const Pendanaan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const savedTahun = localStorage.getItem("tahun");

    if (savedTahun === undefined || savedTahun === null) {
      // Jika nilai tahun masih undefined atau null, panggil localStorage lagi
      let interval = setInterval(() => {
        const newTahun = localStorage.getItem("tahun");
        if (newTahun !== undefined && newTahun !== null) {
          clearInterval(interval); // Hentikan interval jika nilai sudah ditemukan
          dispatch(getParent(newTahun)).then((res) => {
            // ====== hasil authentikasi token =========
            if (res.status === "error") {
              dispatch(logoutUser());
              navigate("/login");
            }
            //============================================
          });
        }
      }, 1000); // Panggil setiap 1 detik (atau sesuai kebutuhan)
    } else {
      // Jika nilai tahun sudah ada, lakukan dispatch langsung
      dispatch(getParent(savedTahun)).then((res) => {
        // ====== hasil authentikasi token =========
        if (res.status === "error") {
          dispatch(logoutUser());
          navigate("/login");
        }
        //============================================
      });
    }
  }, [dispatch, navigate]);

  function createData(id_pendanaan, pendanaan, pagu, parent, level, child) {
    return { id_pendanaan, pendanaan, pagu, parent, level, child };
  }
  const pendanaanData = [];

  const pendanaan = useSelector((state) => state.Master.pendanaan);

  pendanaan.forEach((row) => {
    pendanaanData.push(
      createData(
        row.id_pendanaan,
        row.pendanaan,
        row.pagu,
        row.parent,
        row.level,
        row.child
      )
    );
  });

  const submit = async () => {
    await dispatch(createPendanaan(dataVal));
    await handleClose();
    await setDataVal({
      ...dataVal,
      pendanaan: "",
      pagu: "",
      parent: "",
      level: "",
    });
  };

  //===================Open Dialog ===============================
  const [open, setOpen] = useState(false);

  const [dataVal, setDataVal] = useState({
    pendanaan: "",
    pagu: "",
    parent: "",
    level: "",
  });

  const handleClickOpen = async () => {
    await setDataVal({ ...dataVal, parent: 0, level: 1 });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleInputChange(value) {
    const formattedValue = formatRupiah(value);
    setDataVal({ ...dataVal, pagu: formattedValue });
  }

  function handleKeyPress(e) {
    const charCode = e.charCode;
    if (charCode >= 48 && charCode <= 57) {
      e.preventDefault();
    }
  }

  //=================Menu Item ============================
  const [anchorEl, setAnchorEl] = useState(null);
  const [idPendanaan, setIdPendanaan] = useState("");
  const [statusBtn, setStatusBtn] = useState("");
  // const open = Boolean(anchorEl);

  const handleClick = (event) => {
    const { id_pendanaan,status } = event.currentTarget.dataset;
    setIdPendanaan(id_pendanaan || "");
    setStatusBtn(status || "");
    //console.log(event.currentTarget)
    setAnchorEl(event.currentTarget);
    anchorEl === null ? setAnchorEl(event.currentTarget) : setAnchorEl(null);
  };

  const handleClose2 = async () => {
    await setAnchorEl(null);
  };

  const addPagu = async (id_pendanaan) => {
    await setDataVal({ ...dataVal, parent: id_pendanaan, level: 2 });
    setOpen(true);
    handleClose2();
  };
  const changePendanaan = async (e) => {
    try {
      await setDataVal({ ...dataVal, pendanaan: e.target.value });
    } catch (error) {
      console.log(error);
    }
  };

  //==================== proses child ===========================================================
  const renderChildRow = (child) => {
    return child.map((row) => (
      <TableRow
        key={row.id_pendanaan}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCells align="center" colSpan={2}>
          {row.pendanaan}
        </TableCells>
        <TableCells align="center" colSpan={1}>
          {rupiah(row.pagu)}
        </TableCells>
        <TableCells align="left">
          <ButtonPendanaan
            status="child"
            id_pendanaan={row.id_pendanaan}
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose2={handleClose2}
            idPendanaan={idPendanaan}
            addPagu={addPagu}
            revisiPagu={revisiPagu}
            deletePagu={deletePagu}
            statusBtn={statusBtn}
          />
        </TableCells>
      </TableRow>
    ));
  };

  //========================================================================================================

  //================================== PROSES EDIT PENDANAAN ================================================
  const itemPagu = useSelector((state) => state.Master.pendanaanId);
  const [openRevisi, setOpenRevisi] = useState(false);

  const revisiPagu = async (idPendanaan) => {
    await dispatch(getPendanaanId(idPendanaan));
    setOpenRevisi(true);
    handleClose2();
  };

  const deletePagu = async(idPendanaan)=>{
    // Konfirmasi penghapusan
    const confirmDelete = window.confirm('Apakah Anda yakin ingin menghapus pendanaan ini?');
    if (confirmDelete) {
      await dispatch(deletePendanaanInduk(idPendanaan));
      handleClose2();
    }
  }

  useEffect(() => {
    if (itemPagu) {
      // Memperbarui dataVal saat itemPagu berubah
      setDataVal({
        ...dataVal,
        id_pendanaan: itemPagu.id_pendanaan || "",
        pendanaan: itemPagu.pendanaan || "",
        pagu: itemPagu.pagu || "",
        parent: itemPagu?.parent,
        level: itemPagu.level || "",
      });
    }
  }, [itemPagu]); // Memantau perubahan itemPagu

  const handleCloseRev = () => {
    setOpenRevisi(false);
    setDataVal({
      ...dataVal,
      pendanaan: "",
      pagu: "",
      parent: "",
      level: "",
    });
  };

  const submitRevisi = async () => {
    //console.log(dataVal)
    await dispatch(revisiPendanaan(dataVal));
    await handleCloseRev();
  };

  return (
    <>
      <Typography variant="h4" sx={{ mb: 4 }} color="initial">
        Pendanaan
      </Typography>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography sx={{ m: 2, fontSize: 20 }}></Typography>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <StyledButton variant="contained" onClick={handleClickOpen}>
              {" "}
              Add Pendanaan{" "}
            </StyledButton>
          </Grid>
        </Grid>
        <Divider />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <RowTop>
                <TableTop>#</TableTop>
                <TableTop>Pendanaan</TableTop>
                <TableTop>Pagu</TableTop>
                <TableTop colSpan={2}>Opsi</TableTop>
              </RowTop>
            </TableHead>
            <TableBody>
              {pendanaanData.map((row, index) => (
                <React.Fragment key={row.id_pendanaan}>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCells align="left">{index + 1}</TableCells>
                    <TableCells align="left">{row.pendanaan}</TableCells>
                    <TableCells align="left">{rupiah(row.pagu)}</TableCells>
                    <TableCells align="left">
                      <ButtonPendanaan
                        status="parent"
                        id_pendanaan={row.id_pendanaan}
                        anchorEl={anchorEl}
                        handleClick={handleClick}
                        handleClose2={handleClose2}
                        idPendanaan={idPendanaan}
                        addPagu={addPagu}
                        revisiPagu={revisiPagu}
                        deletePagu={deletePagu}
                        statusBtn={statusBtn}
                      />
                    </TableCells>
                  </TableRow>
                  {renderChildRow(row.child)}
                </React.Fragment>
              ))}
              <TableRow>
                <TableCells colSpan={4}>{""}</TableCells>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <DialogPendanaanAdd
        open={open}
        handleClose={handleClose}
        dataVal={dataVal}
        changePendanaan={changePendanaan}
        handleInputChange={handleInputChange}
        handleKeyPress={handleKeyPress}
        submit={submit}
      />
      <DialogPendanaanRevisi
        open={openRevisi}
        handleClose={handleCloseRev}
        dataVal={dataVal}
        changePendanaan={changePendanaan}
        handleInputChange={handleInputChange}
        handleKeyPress={handleKeyPress}
        submitRevisi={submitRevisi}
      />
    </>
  );
};

export default Pendanaan;
