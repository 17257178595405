import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React from 'react'

const DialogPendanaanRevisi = ({open,handleClose,dataVal,changePendanaan,handleInputChange,handleKeyPress,submitRevisi}) => {
  return (
    <>
        <Dialog open={open} fullWidth maxWidth="xs" onClose={handleClose}>
          <DialogTitle>Revisi Pagu Anggaran </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                label="Sumber Pendanaan"
                type="text"
                fullWidth
                variant="standard"
                value={dataVal?.pendanaan}
                onChange={(e)=>{changePendanaan(e)}}
                sx={{marginBottom:'20px'}}
              />
              <TextField
                autoFocus
                label="Nilai Pagu"
                type="text"
                fullWidth
                variant="standard"
                value={dataVal?.pagu}
                onChange={(e)=>{handleInputChange(e.target.value)}}
                onKeyDown={handleKeyPress}
              />
            </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={submitRevisi}>Send</Button>
          </DialogActions>
        </Dialog>
    </>
  )
}

export default DialogPendanaanRevisi