import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, ListItemIcon, Menu, MenuItem, MenuList, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createIndikator, createSelectPendanaan, getBidang, getIndikatorParent, getParent, getSelectPendanaan } from '../../store/master';
import { formatRupiah, rupiah } from '../../function/FormatRupiah';
import { LibraryAdd, MoreVert } from '@mui/icons-material';
import { RowTop, TableTop } from '../../assets/styled';
import { logoutUser } from '../../store/user';
import { useNavigate } from 'react-router-dom';
import { getSatuan } from '../../store/satuan';
// const StyledButton = styled(Button)({
//     backgroundColor:'#4875EE',
//     color:'#ffffff',
//     margin:'10px',
//   });

const TableCells = styled(TableCell)({
    border: "1px solid black",
  });

const IndikatorKinerja = () => {

      const navigate = useNavigate();
      const dispatch = useDispatch();

      const [indikator,setIndikator] = useState({
        program:'',
        kegiatan:'',
        sub_kegiatan:'',
        satuan:'',
        perencanaan:'',
        kode_bidang:'',
        parent:'',
        id_pendanaan:'',
        kode:''
    });

     // === indikator pendanaan 2 buah yang di select =======
     const [pendanaan,setPendanaan] = useState({id_pendanaan:'',id_sumber_dana:''});
     const dataIndikator = useSelector(state=>state.Master.pendanaan);

    // data child setelah id_pendanaan didapatkan karena select pertama dan kedua saling berkaitan
    const [sumberPendanaan,setSumberPendanaan] = useState([]);

    // ================ tahun yang dipilih user =================================================
    const tahunSelect = useSelector(state=>state.User.tahun);

    const tahun = localStorage.getItem('tahun');

    useEffect(()=>{
       //console.log(tahunSelect)
        dispatch(getParent(tahunSelect))
        .then((res)=>{

            // ====== hasil authentikasi token =========
              if(res.status === 'error'){
                dispatch(logoutUser());
                navigate('/login');
            }
            //============================================

          });

        dispatch(getSelectPendanaan(tahunSelect));  
    },[pendanaan,tahunSelect,dispatch,navigate]);


    const pendanaanChange = async(e) =>{
        await setPendanaan({...pendanaan,id_pendanaan:e.target.value});
        const danaChild = await dataIndikator.find(row => parseInt(row.id_pendanaan) === parseInt(e.target.value));
        await setSumberPendanaan(danaChild?.child);
    }


    const sumberChange = async(e) =>{
        await setPendanaan({...pendanaan,id_sumber_dana:e.target.value});
     }
     

     const selectDanaHandler = async() =>{
        const selected = await sumberPendanaan.find(row => parseInt(row.id_pendanaan) === parseInt(pendanaan.id_sumber_dana));
        await setSelectDana({...selected});
        await setIndikator({...indikator,id_pendanaan:pendanaan.id_sumber_dana});
        await dispatch(getIndikatorParent({id_pendanaan:pendanaan.id_sumber_dana,tahun:tahun}));
        await dispatch(createSelectPendanaan(selected));
        console.log(selectDana);
        console.log(indikator)
    }


    // data sumber dana setelah tombol select di klik 
    const [selectDana,setSelectDana] = useState([]);

    
     const [dataIndikatorNew,setDataIndikatorNew] = useState([]);

//================================================================================================================


    // const pendanaanSelected = useSelector(state => state.Master.selectPendanaan); 

    // // ==== set pendanaan dari database =======
    // useEffect(()=>{
    //     // const selected = sumberPendanaan.find(row => parseInt(row.id_pendanaan) === parseInt(pendanaanSelected[0].id_pendanaan));
    //     if(pendanaanSelected?.length > 0){
    //         setIndikator({...indikator,id_pendanaan:pendanaanSelected[0].id_pendanaan});
    //         setSelectDana(...pendanaanSelected);
    //         dispatch(getIndikatorParent({id_pendanaan:pendanaanSelected[0].id_pendanaan,tahun:tahun}));
    //         //dispatch(getIndikatorParent({id_pendanaan:pendanaan.id_sumber_dana,tahun:tahun}));
    //     }
    // },[pendanaanSelected]);
    

    // const parentIndikator = useSelector(state=>state.Master.indikator);

    // useEffect(()=>{
    //     setDataIndikatorNew(parentIndikator);
    // }, [parentIndikator]);
    
    

  return (
    <>

    {/* ================= select pendanaan ==================================== */}
        <Paper sx={{ width: '100%', overflow: 'hidden', p: 2 }}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 1 }} color="initial">
                Select Pendanaan
            </Typography>
            </Grid>
            <Grid item xs={5}>
                <TextField
                    autoFocus
                    select
                    label="Pendanaan"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={pendanaan.id_pendanaan}
                    onChange={pendanaanChange}
                    helperText="Select Pendanaan"
                >
                {dataIndikator.map((row) => (
                    <MenuItem key={row.id_pendanaan} value={row.id_pendanaan}>
                        {row.pendanaan}
                    </MenuItem>
                ))}
                </TextField>
            </Grid>
            <Grid item xs={5}>
                    <React.Fragment >
                        <TextField
                            autoFocus
                            select
                            label="Sumber Pendanaan"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={pendanaan.id_sumber_dana}
                            onChange={sumberChange}
                            helperText="Select Sumber Pendanaan"
                        >
                    {sumberPendanaan.map((row) => (
                            <MenuItem key={row.id_pendanaan} value={row.id_pendanaan}>
                                {row.pendanaan}
                            </MenuItem>
                    ))}
                    </TextField>
                    </React.Fragment>
                
            </Grid>
            <Grid item xs={2}>
                <Button variant="contained" onClick={selectDanaHandler} >Select Pendanaan</Button>
            </Grid>
        </Grid>
        </Paper>
    {/* ============================================================================================== */}

    
    </>
  )
}

export default IndikatorKinerja