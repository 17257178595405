import { createTheme } from '@mui/material'

const Theme = createTheme({
    palette:{
        custom:{
            white:'#ffffff',
            grey:'#c0c0c0'
        },
        background: {
          default: "linear-gradient(135deg, #43CBFF 10%, #9708CC 100%)"
        },
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600, // Atur nilai ini sesuai dengan definisi layar mobile
          md: 960,
          lg: 1280,
          xl: 1920,
        },
    },
    typography: {
      fontFamily: 'Noto Serif, Arial, sans-serif', // Gunakan Noto Serif sebagai font utama
      fontSize: 14, // Ukuran font dasar
      h1: {
          fontFamily: 'Noto Serif, sans-serif', // Font khusus untuk h1
          fontSize: '2rem', // Ukuran h1
          fontWeight: 700, // Ketebalan font
      },
      h2: {
          fontFamily: 'Noto Serif, sans-serif', // Font khusus untuk h2
          fontSize: '1.5rem', // Ukuran h2
          fontWeight: 600, // Ketebalan font
      },
      body1: {
          fontSize: '1rem', // Ukuran untuk teks biasa
      },
      // Tambahkan lebih banyak pengaturan untuk body2, subtitle1, dll. sesuai kebutuhan
  },
})

export default Theme