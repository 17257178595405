import React,{useState} from 'react'
import { List,ListItemButton,ListItemIcon,ListItemText,Collapse,ListSubheader, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { Home,Group,ExpandLess,ExpandMore, RadioButtonUnchecked, SettingsApplications, Dataset, AccountTree, Summarize } from '@mui/icons-material';

const SideMenu = () => {

    const [open,setOpen] = useState({openId:0});

    let menu =  [
                    {id_menu:1,link:'/',title:'Home',icon:<Home />,key:false,child:[]},
                    {id_menu:2,link:false,title:'Master Data',icon:<Dataset />,key:2,child:[
                        {id_child:1,link:'/master/satuan',title:'Satuan'},
                        {id_child:2,link:'/master/bidang',title:'Bidang / UPTD'},
                        {id_child:3,link:'/master/pendanaan',title:'Pendanaan'},
                        // {id_child:4,link:'/master/indikator_kinerja',title:'Select Pendanaan'},
                        {id_child:4,link:'/master/indikator',title:'Indikator'},
                        {id_child:5,link:'/master/capaian_kinerja',title:'Capaian Kinerja'},
                        
                      ],    
                    },
                    {id_menu:3,link:false,title:'Manage',icon:<AccountTree />,key:3,child:[
                        {id_child:1,link:'/manage/kegiatan_anggaran',title:'Kegiatan Anggaran'},
                        {id_child:2,link:'/manage/input_capaian',title:'Input Capaian'},
                      ],    
                    },
                    {id_menu:4,link:false,title:'User',icon:<Group />,key:4,child:[
                        {id_child:1,link:'/user/user_data',title:'Data User'},
                      ],    
                    },
                    {id_menu:5,link:false,title:'Report',icon:<Summarize />,key:5,child:[
                      {id_child:1,link:'/report/capaian_report',title:'Capaian Kinerja'},
                      {id_child:2,link:'/report/keg_anggaran_report',title:'Kegiatan Anggaran'},
                    ],    
                  },
                    {id_menu:6,link:'/Settings',title:'Setting',icon:<SettingsApplications />,key:false,child:[]},
                ];
      
      // const kode_bidang = localStorage.getItem('kode_bidang');
      const hak_akses = localStorage.getItem('hak_akses');

      // Fungsi untuk menyesuaikan objek-objek yang ditampilkan berdasarkan hak akses
      const filterMenuByAccess = (item) => {
        if (hak_akses === 'user') {
          // Menyesuaikan item-menu yang harus ditampilkan untuk hak akses 'user'
          if (item.id_menu === 1 || item.id_menu === 3) {
            return true;
          }
          return false;
        }
        return true; // Jika hak akses bukan 'user', tampilkan semua menu
      };

      // Menyesuaikan menu berdasarkan hak akses
      menu = menu.filter(filterMenuByAccess);

    const handleClick = (id) => {
             open.openId === id ? setOpen({...open,openId:0}) : setOpen({...open,openId:id})
    };



  return (
    <>
          <div>
         <List>
                <ListSubheader>{`Menu`}</ListSubheader>
                {menu.map((row,index)=>(
                    <div key={index}>
                        <ListItemButton
                        sx={{
                          color: row.id_menu === open.openId ? '#00D0FA' : '', // Memberi warna biru pada item menu yang dipilih
                        }}
                        component={Link} to={row.link} onClick={()=>handleClick(row.key)}>
                          <ListItemIcon>
                              {row.icon}
                          </ListItemIcon>
                          <ListItemText primary={row.title} />
                          {row.key ? open.openId === row.id_menu ? <ExpandLess /> : <ExpandMore />: ''}
                        </ListItemButton>
                        <Collapse in={open.openId === row.key} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {row.child.map((child,index)=>(
                                        <div key={index}>
                                            <ListItemButton sx={{ pl: 4 }} component={Link} to={child.link}>
                                                <ListItemIcon>
                                                    <RadioButtonUnchecked />
                                                </ListItemIcon>
                                                <ListItemText primary={child.title} />
                                            </ListItemButton>
                                        </div>
                                ))}
                            </List>
                        </Collapse>
                    </div>
                    
                ))}
                
            </List>
    </div>
    <Box sx={{position:'absolute',bottom:16,left:30}}>
            {/* <img src={require('../assets/orbit_footer.png')} alt="Logo" style={{ width: '150px', height: '150px' }} /> */}
      </Box>
    </>

  )
}

export default SideMenu