import { Button, Divider, Grid, Paper, styled, Table, TableContainer, TableHead, Typography } from '@mui/material';
import React from 'react';
import { RowTop, TableTop } from '../../assets/styled';

const StyledButton = styled(Button)({
  backgroundColor:'#4875EE',
  color:'#ffffff',
  margin:'10px',
})

const PendanaanNew = () => {
  return (
    <>
      <Paper sx={{width: '100%', overflow:'hidden'}}>

        <Grid container spacing={2}>
          <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-start">
            <Typography sx={{m:2,fontSize:20}}></Typography>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <StyledButton 
                variant="contained"
                onClick={()=>{}}
              >  Add Pendanaan </StyledButton>
          </Grid>
        </Grid>
        <Divider />
        <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <RowTop>
                  <TableTop>#</TableTop>
                  <TableTop>Pendanaan</TableTop>
                  <TableTop>Pagu</TableTop>
                  <TableTop colSpan={2}>Opsi</TableTop>
                </RowTop>
              </TableHead>
              <TableBody>
                
              </TableBody>
            </Table>
        </TableContainer>

      </Paper>
    
    </>
  )
}

export default PendanaanNew;